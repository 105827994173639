<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        text
        v-on="on"
      >
        <img
          :src="lang === 'en' ? langList.en.img : langList.vi.img"
          class="mr-2"
          width="23"
          height="17"
        >
        <span
          style="font-size: 17px"
          v-text="lang === 'en' ? langList.en.text : langList.vi.text"
        />
      </v-btn>
    </template>

    <v-list
      :tile="false"
      flat
      nav
    >
      <v-btn
        text
        width="100%"
        class="justify-start"
        @click="setLocale(langList.vi.text)"
      >
        <img
          :src="langList.vi.img"
          class="mr-2"
          width="21"
          height="15"
        >
        <span
          style="font-size: 17px"
          v-text="langList.vi.text"
        />
      </v-btn>
      <v-btn
        text
        width="100%"
        class="justify-start"
        @click="setLocale(langList.en.text)"
      >
        <img
          :src="langList.en.img"
          class="mr-2"
          width="21"
          height="15"
        >
        <span
          style="font-size: 17px"
          v-text="langList.en.text"
        />
      </v-btn>
    </v-list>
  </v-menu>
</template>

<script>
  import { localize } from 'vee-validate'
  import TokenService from '@/helpers/token'
  import vi from 'vee-validate/dist/locale/vi'
  import en from 'vee-validate/dist/locale/en'
  export default {
    data () {
      return {
        lang: TokenService.getLang() || 'vi',
        langList: {
          vi: {
            text: 'vi',
            img: require('@/assets/images/flags/vi.png'),
          },
          en: {
            text: 'en',
            img: require('@/assets/images/flags/en.jpg'),
          },
        },
      }
    },
    methods: {
      setLocale (lang) {
        this.$router.go(0)
        this.lang = lang
        TokenService.setLang(lang)
        this.$i18n.locale = lang
        if (lang === 'en') {
          localize(lang, en)
        } else {
          localize(lang, vi)
        }
      },
    },
  }
</script>

<style scoped>
</style>
